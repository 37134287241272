<template>
  <div>
    <v-card-title style="background-color: transparent !important">
      <v-row>
        <v-col>
          <h3 class="mt-1" style="display: inline-block">مدیریت {{ title }}</h3>
        </v-col>
        <v-col class="btn-col">
          <v-btn
            style="float: left"
            @click="gotoNewPerson()"
            v-if="role != 'reception'"
            class="primary-btn ms-5 me-5"
          >
            <!--<h6 class="mt-1">{{ faJob }} جدید +</h6>-->
            <v-icon left>person_add</v-icon>
            افزودن
          </v-btn></v-col
        >
      </v-row>
    </v-card-title>
    <v-card class="pa-3 cards">
      <v-card-text class="pa-0">
        <!--<v-container class="">-->
        <v-row v-if="role != 'reception'" align-v="end" class="mt-2">
          <v-col> </v-col>
          <v-col>
            <v-btn
              style="float: left"
              class="secondary-btn mb-2 ms-3 me-3"
              @click="$refs.tableExcel.$el.click()"
              >دریافت اکسل</v-btn
            >
            <vue-excel-xlsx
              v-show="false"
              ref="tableExcel"
              :data="Items"
              :columns="
                excelFields.map((x) => {
                  return {
                    label: x.label,
                    field: x.key,
                  };
                })
              "
              :filename="'لیست ' + title"
              :sheetname="currentDate"
            >
              دریافت اکسل
            </vue-excel-xlsx>
          </v-col>
        </v-row>
        <v-text-field
          v-model="Filter"
          prepend-inner-icon="mdi-magnify"
          label="جستجو"
          hide-details
          single-line
          class="text-right w-80 mb-2 search-input dr-search"
          filled
          rounded
          clearable
        ></v-text-field>
        <b-table
          responsive
          show-empty
          :fields="
            role == 'reception'
              ? Fields.filter((x) => x.key != 'operation')
              : Fields
          "
          :items="Items"
          empty-text="رکوردی برای نمایش وجود ندارد"
          empty-filtered-text="رکوردی برای نمایش وجود ندارد"
          striped
          :busy="Busy"
          :filter="Filter"
          :current-page="CurrentPage"
          :per-page="PerPage"
          @filtered="onFiltered"
          class="pa-2"
        >
          <template v-slot:head()="data">
            <div
              class="table-header"
              style="text-align: center; vertical-align: middle"
            >
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(index)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
            </div>
          </template>
          <template v-slot:cell(operation)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-btn
                class="primary-btn pa-2"
                :to="'/admin/' + edit + data.item.id"
                ><v-icon> edit</v-icon></v-btn
              >
            </div>
          </template>
          <div slot="table-busy" class="text-center primary--text my-2">
            <v-progress-circular
              indeterminate
              color="primary"
              class="align-middle"
            ></v-progress-circular>
          </div>
        </b-table>
        <v-pagination
          v-model="CurrentPage"
          :length="Math.ceil(TotalRows / PerPage)"
          :total-visible="5"
          prev-icon="arrow_back"
          next-icon="arrow_forward"
        ></v-pagination>
        <v-select
          label="تعداد در هر صفحه:"
          style="width: 150px"
          v-model="PerPage"
          :items="perPageOptions"
          item-text="text"
          item-value="value"
          class="me-2 ms-2"
        ></v-select>
        <!--</v-container>-->
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from "moment-jalaali";

export default {
  props: [
    "get",
    "job",
    "faJob",
    "new",
    "edit",
    "title",
    "Fields",
    "excelFields",
  ],
  data() {
    return {
      CurrentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      PerPage: 10,
      TotalRows: "",
      Filter: "",
      Busy: true,
      Items: [],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
    };
  },
  methods: {
    gotoNewPerson() {
      let role = this.role == "reception" ? "reception" : "admin";
      this.$router.push("/" + role + "/" + this.new);
    },
    showPeople() {
      //NOTE getting all employees info when page mount
      this.$http
        .post(
          this.baseUrl + this.get,
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.Busy = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.showPeople();
  },
};
</script>
